function sliders() {
    $('.hero-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'dots-horizontal'
    });
    
    $('.hero-carousel__vertical').slick({
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'dots-vertical'
    });
}

module.exports = sliders;