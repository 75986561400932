function cardsData() {
    fetch('cards.json')
        .then((res) => res.json())
        .then((data) => {
            const cardListEl = document.querySelector("#products-wrapper");

            data.forEach(function (card) {
                const cardContent = `
                    <li class="products-wrapper__item fade">
                        <div class="products-wrapper__img-wrap">
                            <img class="favorite-bg" src="./icons/favorite-bg.svg">
                            <img class="favorite" src="./icons/favorite.svg" >
                            <img src="${card.img}">
                        </div>
                        <div class="products-wrapper__info">
                            <a href="./projects-details.html?id=${card.id}">
                                ${card.name}
                            </a>
                            <p>Под заказ</p>
                            <span>${card.prise} ₽</span>
                        </div>
                        <button type="button" class="btn btn--black products-wrapper__btn">В корзину</button>
                    </li>
                `;
                cardListEl.innerHTML += cardContent
            });
    })

    fetch('arts.json')
        .then((res) => res.json())
        .then((data) => {
            const cardListEl = document.querySelector("#arts-wrapper");

            data.forEach(function (card) {
                const cardContent = `
                    <li class="products-wrapper__item fade">
                        <div class="products-wrapper__img-wrap">
                            <img class="favorite" src="./icons/favorite.svg" >
                            <img src="${card.img}">
                        </div>
                        <div class="products-wrapper__info">
                            <a href="./projects-details.html?id=${card.id}">
                                ${card.name}
                            </a>
                            <p>Под заказ</p>
                            <span>${card.prise} ₽</span>
                        </div>
                        <button type="button" class="btn btn--black products-wrapper__btn">В корзину</button>
                    </li>
                `;
                cardListEl.innerHTML += cardContent
            });
    })
};

module.exports = cardsData;