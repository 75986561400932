import 'slick-carousel'

import filterCards from './modules/filterCards';
import cardsData from './modules/cardsData';
import sliders from './modules/sliders';
import searchInput from './modules/searchInput';
import navMenu from './modules/navMenu';
import priceRange from './modules/priceRange';

cardsData();
sliders();
navMenu();
priceRange();
filterCards();  
searchInput();