function navMenu() {
    const currentPath = window.location.pathname;

    if (currentPath !== '/page.html') {
        document.querySelector('nav').classList.add('filled');
    }

    document.querySelectorAll('.nav-link').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const id = e.target.getAttribute('href').replace('#', '');
            
            if (currentPath === '/page.html') {
                document.querySelector('.nav').classList.remove('filled');
            }
    
            if (item.classList.contains('active')) {
                item.classList.remove('active');
                document.getElementById(id).classList.remove('active');
            } else {
                document.querySelector('.nav').classList.add('filled');
                document.querySelectorAll('.nav-link').forEach(
                    (child) => child.classList.remove('active')
                );
                document.querySelectorAll('.content-section').forEach(
                    (child) => child.classList.remove('active')
                );
                
                item.classList.add('active');
                document.getElementById(id).classList.add('active');
            }
        });
    });
}

module.exports = navMenu;